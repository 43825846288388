@use '../../globalStyles/mixins' as m;

.experience-container {
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 5rem;
}

@include m.mobile-down {
  .experience-container {
    margin-bottom: 1rem;
  }
}

@include m.mobile {
  .experience-container {
    margin-bottom: 1rem;
  }
}
