@use '../../../globalStyles/mixins' as m;
@use '../../../globalStyles/variables' as v;

.experience {
  width: 100%;
  height: 100%;
  display: flex;
  user-select: none;
  margin-bottom: 1.375rem;

  padding: 1rem;
  border-radius: v.$border-radius;
  border: 1px solid m.color(grey-2);
  box-sizing: border-box;
  gap: 1rem;

  .item__image {
    min-width: 4rem;
    max-width: 6rem;
    border-radius: v.$border-radius;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    // border: 1px solid m.color(grey-0);
    overflow: hidden;
    box-shadow: 0 0 0 1px m.color(grey-0);

    img {
      object-fit: contain;
      box-shadow: v.$box-shadow;
      width: 100%;
      height: auto;
      background: m.color(white);
    }
  }

  .item__details {
    width: 100%;

    .title {
      font-size: m.size(large-0);
      font-weight: m.weight(medium);
      margin: 0 0 0.25rem 0;
    }

    .term {
      font-size: m.size(small-0);
      color: m.color(grey-8);
      margin-left: 2px;
    }
  }

  .tags {
    display: flex;

    .tag {
      @include m.tech-tags;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: m.color(grey-6);
  }
}

@include m.mobile-down {
  .experience {
    flex-direction: column;
    padding: 0;
    border: 0;
    grid-gap: 0.225rem;
    gap: 0.225rem;

    .item__details {
      padding-top: 0.5rem;
      padding-left: 0.625rem;

      .title {
        border-bottom: 1px solid m.color(grey-2);
        padding-bottom: 0.225rem;

        span {
          display: block;
        }
      }
    }
  }
}

@include m.mobile {
  .experience {
    flex-direction: column;
    padding: 0;
    border: 0;
  }
}
