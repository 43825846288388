.fade-enter {
  opacity: 0;
  animation: slideIn 0.6s;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 0;
  animation: slideOut 0.6s;
}

.fade-exit-active {
  opacity: 1;
  transition: opacity 500ms;
}

@keyframes slideIn {
  0% {
    opacity: 0;
  }

  100% {
    filter: blur(10px);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    opacity: 0;
    filter: blur(0px);
  }

  100% {
    filter: blur(10px);
    opacity: 1;
  }
}
