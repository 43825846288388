$border-radius: 1.25rem;
$border-radius-light: 0.25rem;

$size: (
  huge-1: 5rem,
  huge-0: 4rem,
  large-15: 3rem,
  large-14: 2.825rem,
  large-13: 2.75rem,
  large-12: 2.625rem,
  large-11: 2.5rem,
  large-10: 2.375rem,
  large-7: 2rem,
  large-6: 1.875rem,
  large-5: 1.75rem,
  large-4: 1.625rem,
  large-3: 1.5rem,
  large-2: 1.375rem,
  large-1: 1.25rem,
  large-0: 1.125rem,
  default: 1rem,
  small-0: 0.875rem,
  small-1: 0.75rem,
  small-2: 0.625rem,
  small-3: 0.5rem,
);

$weight: (
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  light-bold: 600,
  semi-bold: 700,
  bold: 800,
  black: 900,
);

$color: (
  primary: rgb(255, 175, 25),
  primary-light: rgb(255, 247, 234),
  primary-opacity: rgba(255, 175, 25, 0.1),
  primary-opacity-heavy: rgba(255, 175, 25, 0.2),
  primary-opacity-heavy-light: rgb(255, 231, 185),
  primary-emphasis: rgb(255, 98, 26),
  primary-emphasis-opacity: rgb(255, 98, 26, 0.1),
  primary-emphasis-light: rgb(255, 230, 219),
  alert: rgb(255, 84, 0),
  alert-opacity: rgba(255, 84, 0, 0.15),
  alert-opacity-light: rgb(254, 230, 218),
  safe: rgb(0, 150, 4),
  safe-opacity: rgba(0, 150, 4, 0.1),
  safe-opacity-light: rgb(229, 244, 229),
  dark-event: rgb(26, 75, 255),
  dark-event-opacity: rgba(26, 75, 255, 0.1),
  dark-event-opacity-light: rgb(232, 237, 255),
  main: #222,
  white: #fff,
  grey-light: rgb(250, 250, 250),
  grey-0: rgb(240, 240, 240),
  grey-1: rgb(239, 240, 241),
  grey-2: rgb(227, 230, 233),
  grey-3: rgb(216, 224, 231),
  grey-4: rgb(204, 213, 221),
  grey-5: rgb(188, 199, 211),
  grey-6: rgb(167, 178, 190),
  grey-7: rgb(156, 160, 165),
  grey-8: rgb(138, 142, 148),
  grey-9: rgb(118, 123, 131),
  grey-10: rgb(91, 95, 102),
  grey-11: rgb(63, 66, 71),
);
$background-color: (
  main: #222,
);

$box-shadow: 0 0.08em 0.35em rgb(0 0 0 / 18%);

$breakpoints: (
  mobile: 576px,
  tablet: 768px,
  desktop: 1024px,
  big-desktop: 1200px,
);
